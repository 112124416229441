import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from '../HomePage'

import Homepage3 from '../HomePage3'
import BlogPage from '../blogpage'
import BlogDetailsPage from '../BlogDetailsPage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import './App.css';
import Loading from '../../components/loading';

const App = () => { 

  const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        setTimeout(() => setLoading(false), 3300)
    }, [])
    
    if (loading) {
        return <Loading/>
    }
  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage3} />
            <Route path='/home' component={Homepage} />
            
            <Route path='/Blog' component={BlogPage} />
            <Route path='/Blog-details' component={BlogDetailsPage} />
          </Switch>
          <Footer/>
          <Scrollbar/>
      </Router>
      
    </div>
  );
}

export default App;
