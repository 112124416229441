import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Welcome = () => {
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Welcome to our big day</h2>
                            <p>The TOHS Family and the NFORGWEI Family is priviledged to count you amongst their guests at the occasion of the wedding of their children. We are glad to share these wondrous moments with you.</p>
                            <div className="btn">
                                <AnchorLink href='#rsvp' >Rsvp</AnchorLink>
                            </div>
                            <div className="btn">
                                <Link to="" type="button" className="" data-toggle="modal" data-target="#mapModal">
                                    See Location
                                </Link>
                                
                            </div>
                            <div className="modal fade" id="mapModal" tabIndex="-1" role="dialog" aria-labelledby="mapModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d127393.52057476001!2d11.429410135652345!3d3.799796375592504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x108bcf86f33e9e27%3A0x6ce698fc40310153!2sPoste%20Centrale%2C%20Yaound%C3%A9!3m2!1d3.8606987!2d11.520498799999999!4m5!1s0x108bd7e1f2a84773%3A0xcabbc3e1f163d7!2scomplexe%20mundi%2C%2017022%20yd%C3%A9%20yaounde%2C%20ntoun!3m2!1d3.7381233!2d11.513450899999999!5e0!3m2!1sfr!2scm!4v1698949472053!5m2!1sfr!2scm" width={600} height={450}   loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;