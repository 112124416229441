import React from 'react'
import {Link} from 'react-router-dom'
import { useState, useEffect } from "react";
import axios from 'axios';
import Sectiontitle from '../section-title'
import { event_url } from '../../helpers/api';

import './style.css'

const Location = () => {
    const [ceremonies, setCeremonies] = useState([]);

    const getCeremonies = async() => {
        const response = await axios.get(event_url);
        setCeremonies(response.data);
    }

    useEffect(() => {
        getCeremonies();
    }, [])

    return(
        <div id="event" className="service-area section-padding">
            <div className="container">
                <Sectiontitle section={'When & Where'}/>
                <div className="service-area-menu">
                {
                    ceremonies.map((ceremony) => 
                        {
                            if(ceremony.id % 2 !== 0){
                               return (
                                    <div className="Ceremony-wrap" key={ceremony.id}>
                                        <div className="row">
                                        
                                            <div className="col-lg-5">
                                                <div className="ceromony-img">
                                                    <img src={ceremony.image} alt={ceremony.name}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="ceromony-content">
                                                    <h3>{ceremony.name}</h3>
                                                    <span>{ceremony.date}</span>
                                                    <span>{ceremony.venue} </span>
                                                    <p>{ceremony.description} </p>
                                                    <Link to="" type="button" className="" data-toggle="modal" data-target="#mapModal">
                                                        See Location
                                                    </Link>
                                                    {/* <Link to="/">See Location</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="Ceremony-wrap" key={ceremony.id}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="ceromony-content ceromony-content2">
                                                    <h3>{ceremony.name}</h3>
                                                    <span>{ceremony.date}</span>
                                                    <span>{ceremony.venue}</span>
                                                    <p>{ceremony.description} </p>
                                                    <Link to="" type="button" className="" data-toggle="modal" data-target="#mapModal">
                                                        See Location
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="ceromony-img">
                                                    <img src={ceremony.image} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        } )
                }
                    <div className="modal fade" id="mapModal" tabIndex="-1" role="dialog" aria-labelledby="mapModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d127393.52057476001!2d11.429410135652345!3d3.799796375592504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x108bcf86f33e9e27%3A0x6ce698fc40310153!2sPoste%20Centrale%2C%20Yaound%C3%A9!3m2!1d3.8606987!2d11.520498799999999!4m5!1s0x108bd7e1f2a84773%3A0xcabbc3e1f163d7!2scomplexe%20mundi%2C%2017022%20yd%C3%A9%20yaounde%2C%20ntoun!3m2!1d3.7381233!2d11.513450899999999!5e0!3m2!1sfr!2scm!4v1698949472053!5m2!1sfr!2scm" width={600} height={450}   loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                            </div>
                        </div>
                    </div> 
                    <div className="pt-2 small">
                        <p>
                        <i> <strong>To Stay: </strong>
                        We have managed to get a special rate of XAF 53,000/ night for our special ones . You can dial
                        <a href="tel:00237653333233" target="_blank"> +237 653 33 32 33</a>
                         for bookings.</i>
                       </p>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Location;