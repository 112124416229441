import React from 'react';
import { useState } from 'react';
import './style.css'
import axios from 'axios';
import SweetAlert from "sweetalert-react"
import 'sweetalert/dist/sweetalert.css';
import { store_booking_url } from '../../helpers/api';

const Rsvp = () => {

    const [booking, setBooking] = useState({
        username : '',
        email : '',
        message : '',
        events : '',
        rsvp : '',
    });

    const [alert, setAlert] = useState();
    const [loader, setLoader] = useState();

    const [error, setError] = useState('');

    const {username, email, message, events, rsvp} = booking;

    const handleInputChange = (e) => {
        setBooking({...booking, [e.target.name] : e.target.value})
    }

    const saveBooking = async(e) => {
        setLoader(true);
        e.preventDefault();
        
        await axios.post(store_booking_url, booking);
        setLoader(false);
        setAlert(true);
        setBooking({
            username : '',
            email : '',
            message : '',
            events : '',
            rsvp : '',
        })
    }

    return (
        <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="rsvp-wrap">
                            <div className="col-12">
                                <div className="section-title section-title4 text-center">
                                    <h2>Be Our Guest</h2>
                                    <h4>The RSVP is no more available!</h4>
                                </div>
                            </div>
                            {/* <form onSubmit={(e) => saveBooking(e)}>
                                <div className="contact-form form-style">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <input type="text" value={username} onChange={(e) => handleInputChange(e)} placeholder="Your Name*" id="fname" name="username" />
                                            <p>{error.username ? error.username : ''}</p>
                                        </div>
                                        <div className="col-12  col-sm-6">
                                            <input type="text" placeholder="Your Email*" onChange={(e) => handleInputChange(e)} value={email} id="email" name="email" />
                                            <p>{error.email ? error.email : ''}</p>
                                        </div>
                                        <div className="col col-sm-6 col-12">
                                            <select className="form-control" onChange={(e) => handleInputChange(e)} value={rsvp} name="rsvp">
                                                <option disabled value="">Alone or Couple*</option>
                                                <option value="1">Alone</option>
                                                <option value="2">Couple</option>
                                            </select>
                                            <small className="small">Are you coming alone or with a guest ?</small>
                                            {/* <p>{error.rsvp ? error.rsvp : ''}</p> 
                                        </div>
                                        <div className="col col-sm-6 col-12">
                                            <select className="form-control" onChange={(e) => handleInputChange(e)} value={events} name="events">
                                                <option disabled value="">I am Attending*</option>
                                                <option value="1">Wedding ceremony</option>
                                                <option value="2">Reception party</option>
                                                <option value="3">All events</option>
                                            </select>
                                            <p>{error.events ? error.events : ''}</p>
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <textarea className="contact-textarea" value={message} onChange={(e) => handleInputChange(e)} placeholder="Message" name="message"></textarea>
                                            <p>{error.message ? error.message : ''}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button id="submit" type="submit" className="submit gap-1">Send Invitation 
                                        {
                                            loader === true ?
                                            (<div className="spinner-border spinner-border-sm pl-2" role="status">
                                                <span className="sr-only"> Loading...</span>
                                            </div>) : ""
                                        }
                                           
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <SweetAlert
                                show={alert}
                                title="Congratulations!"
                                text="You just RSVP for Casey and Renald Wedding celebration. Make sure to check your email to get your invitation card and other modalities. Regards."
                                animation="slide-from-top"
                                icon="success"
                                onConfirm={() => setAlert(false)}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default Rsvp;