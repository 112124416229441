import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import './style.css'
import { story_url } from '../../helpers/api'


const Story = () =>{
    const [stories, setStories] = useState([])
    const getStories = async() => {
        const response = await axios.get(story_url)
        setStories(response.data)
    }

    useEffect(() => {
        getStories();
    }, [])

    return(
        <div id="story" className="story-area section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title text-center">
                        <h2>Our Story</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="story clearfix">
                            <div className="story-top"></div>
                            <div className="content-wrapper">
                                {
                                    stories.map((story) => (
                                        <div className="item" key={story.id}>
                                            <div className="story-icon">
                                                <span className="flaticon-birds-in-love"></span>
                                            </div>
                                            <div className="story-content">
                                                <div className="image-wrap">
                                                    <div className="single-image">
                                                        <div>
                                                            <img src={story.image} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h2>{story.title}</h2>
                                                <span className="date">{story.date}</span>
                                                <p>{story.story} </p>
                                                {/* <div className="story-button">
                                                    <Link className="theme-btn" to="/"  title="READ MORE">READ MORE</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    ))
                                }
                                
                            </div>
                            <div className="story-bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Story;