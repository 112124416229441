import React from 'react'

import './style.css'

const Loading = () => {
  return (
    <div className="alllove">
        <span className="love love1 fa fa-heart"></span>
        <span className="love love2 fa fa-heart"></span>
        <span className="love love3 fa fa-heart"></span>
        <span className="love love4 fa fa-heart"></span>
        <span className="love love5 fa fa-heart"></span>
        <span className="love love2 fa fa-heart"></span>
        <span className="love love3 fa fa-heart"></span>
        <h1>Loading Love...</h1>
    </div>
  )
}

export default Loading