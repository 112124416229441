import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import axios from 'axios';
import classnames from 'classnames';
import Sectiontitle from '../section-title'
import { teams_url } from '../../helpers/api';
import { team_categories_url } from '../../helpers/api';

import './style.css'

const People = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [members, setMembers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('parents');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const fetchTeamMember = async() => {
       const response =  await axios.get(teams_url);
       setMembers(response.data)
       console.log(members)
    }

    const fetchCategories = async() => {
        const response = await axios.get( team_categories_url);
        setCategories(response.data);
    }

    const HandleCategoryClick = category => {
        toggle(category.id); 
        setSelectedCategory(category.category);
    }

    useEffect(() => {
        fetchCategories();
        fetchTeamMember();
    }, []);

    return(

        <div id="people" className="person-area section-padding pb-70">
            <div className="container">
                <Sectiontitle section={'The Wedding Party'}/>
                <div className="person-area-menu">
                    <div className="person-btn">
                        <Nav tabs>
                        {
                            categories.map((category) => (
                                <NavItem key={category.id}>
                                    <NavLink
                                        className={classnames({ active: activeTab === category.id })}
                                        onClick={
                                            () => HandleCategoryClick(category)
                                                }
                                    >
                                        {category.category.charAt(0).toUpperCase() + category.category.slice(1)}
                                    </NavLink>
                                </NavItem>
                            )) 
                        }
                    
                        </Nav>
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={activeTab}>
                            <div className="Groomsman-wrap">
                                <div className="row">
                                    {
                                        members.map((member) => {
                                            if(member.category === selectedCategory){
                                                return (
                                                    <div className="col-lg-4 col-md-6 col-sm-6" key={member.id}>
                                                    <div className="person-wrap" >
                                                        <div className="person-img">
                                                            <img src={member.photo} alt=""/>
                                                            {/* <div className="social-list">
                                                                <ul>
                                                                    <li>
                                                                        <a href={member.facebook} target="_blank"><i className="fa fa-facebook-f"></i></a>
                                                                    </li>
                                                                    <li>
                                                                        <a href={member.linkedin}  target="_blank"><i className="fa fa-linkedin"></i></a>
                                                                    </li>
                                                                
                                                                </ul>
                                                            </div> */}
                                                        </div>
                                                        <div className="person-content">
                                                            <h3>{member.name}</h3>
                                                            <span>{member.role}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                                
                                            }
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </TabPane>
                     
                    </TabContent>
                </div>
            </div>
        </div>
        
    )
}

export default People;