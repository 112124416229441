import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Sectiontitle from '../section-title'
import './style.css'
import { gallery_url } from '../../helpers/api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const Gallery = () => {
    const [photos, setPhotos] = useState([]);

    const getPhotos = async() => {
        const response = await axios.get(gallery_url)
        setPhotos(response.data)
    }

    useEffect(() => {
      getPhotos();
    }, [])
    
    return (
      <div id="gallery" className="Gallery-section section-padding pb-70">
          <Sectiontitle section={'Some Key Moments'}/>
          <div className="container">
              <div className="row">
                  {
                    photos.map((item) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={item.id}>
                            <div className="gallery-img">
                              <ReactFancyBox
                                  thumbnail={item.image}
                                  image={item.image}/>
                            </div>
                        </div>
                    ))
                  }
              </div>
              <div className='btn' style={{textAlign: 'center'}}>
                  <Link to={{ pathname: "https://themomentpictures.pixieset.com/caseyandrenald/" }} target="_blank">See More Photos</Link>
              </div>
          </div>
      </div>
    );
}

export default Gallery;